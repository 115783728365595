import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`We often toy with pilot projects within `}<a parentName="p" {...{
        "href": "https://www.esa.int/"
      }}>{`ESA`}</a>{` to advance science, but also working methods. These projects sometimes grow into something permanent, sometimes they remain attempts that tought us some valuable lesson. In these pages we link to resources that are related to these projects (either being produced during them or being related to them) and that we think can be of inspiration.`}</p>
    <p>{`-- But the fool on the hill sees the sun going down ... and the eyes in his head see the world spinning around.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      