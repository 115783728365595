import React from 'react'
import { Helmet } from 'react-helmet'
import { graphql, withPrefix } from 'gatsby'
import styled from 'styled-components'

import Page, { Section } from '../shared/page-commons'
import { ACTBanner } from '../shared/banner'
import { absoluteSize } from '../shared/common-styles'
import { Grid, Card, CardActionCall, CardTextGroup } from '../shared/card-grid'
import MDBody from './_cool-stuff.md'

const Ratio = styled.div`
  padding-bottom: 125%;
`

const Title = styled.h3`
  margin: 0;
  font-size: 1.125rem;
  color: var(--gray-9);
`

const ResearchArea = styled.p`
  margin: 0;
  flex-grow: 1;
  margin-top: 5px;
  color: var(--gray-8);
`

const Image = styled.img`
  ${absoluteSize};
  display: block;
  object-fit: cover;
`

const GradientTextGroup = styled(CardTextGroup)`
  ${absoluteSize};
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  top: 30px;
  height: calc(100% - 30px);
  background-image: linear-gradient(
    to bottom,
    hsla(0, 0%, 0%, 0) 0%,
    hsla(0, 0%, 0%, 0.006) 9.1%,
    hsla(0, 0%, 0%, 0.022) 17.6%,
    hsla(0, 0%, 0%, 0.047) 25.7%,
    hsla(0, 0%, 0%, 0.081) 33.2%,
    hsla(0, 0%, 0%, 0.121) 40.4%,
    hsla(0, 0%, 0%, 0.166) 47.2%,
    hsla(0, 0%, 0%, 0.216) 53.6%,
    hsla(0, 0%, 0%, 0.268) 59.8%,
    hsla(0, 0%, 0%, 0.321) 65.8%,
    hsla(0, 0%, 0%, 0.375) 71.6%,
    hsla(0, 0%, 0%, 0.428) 77.3%,
    hsla(0, 0%, 0%, 0.478) 83%,
    hsla(0, 0%, 0%, 0.524) 88.6%,
    hsla(0, 0%, 0%, 0.565) 94.3%,
    hsla(0, 0%, 0%, 0.6) 100%
  );
`

function CoolStuffCard(props) {
  const { relatedLink, ...restProps } = props

  return (
    <Card {...restProps}>
      <figure css="position: relative;">
        <Ratio />
        <Image
          src={withPrefix(relatedLink.image_src)}
          alt={relatedLink.title}
        />
        <GradientTextGroup>
          <div css="padding-top: 80px; width: 100%;">
            <Title>{relatedLink.title}</Title>
            <ResearchArea>{relatedLink.headline}</ResearchArea>
          </div>
        </GradientTextGroup>
      </figure>
      <CardTextGroup>
        <CardActionCall>Visit</CardActionCall>
      </CardTextGroup>
    </Card>
  )
}

export default function CoolStuffPage(props) {
  return (
    <Page bgColor="surface">
      <Helmet>
        <meta
          name="description"
          content="Larger projects and demos that originated in the ACT"
        />
        <meta
          property="og:description"
          content="Larger projects and demos that originated in the ACT"
        />
        <meta
          name="twitter:description"
          content="Larger projects and demos that originated in the ACT"
        />
      </Helmet>
      <ACTBanner>Cool stuff</ACTBanner>
      <Section textColumn>
        <MDBody />
      </Section>
      <Section bgColor="background">
        <Grid>
          {props.data.links.nodes.map(({ frontmatter, fields }) => {
            return (
              <CoolStuffCard
                key={frontmatter.title}
                relatedLink={frontmatter}
                document={frontmatter.document_url}
                href={frontmatter.external_url}
                to={frontmatter.internal_url || fields.slug}
              />
            )
          })}
        </Grid>
      </Section>
    </Page>
  )
}

export const query = graphql`
  query CoolStuffPage {
    links: allMdx(
      filter: {
        fileAbsolutePath: { glob: "**/src/collections/related_links/**" }
      }
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      nodes {
        frontmatter {
          image_src
          title
          headline
          internal_url
          external_url
          document_url
        }
        fields {
          slug
        }
      }
    }
  }
`
